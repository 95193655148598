import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Link } from 'gatsby'

const CreditPage = () => {
  return (
    <Layout hero={<HeroImage title="Credit Application" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col sm={10}>
          <p>If you are interested in opening a Commercial Credit account with Metalcorp Steel, 
            please proceed to our; <br></br>
            <a className="button primary mt-2" href="https://www.inteflow.com.au/Integate/frmIntegate.aspx?company=BLUESCOPE&merchant=ORRCON&operator=INTERFACE&encp=SEWFj49n2lp0r0KaXwrQnBsaW22nAVpaC0BIVDHi3uY=SlRcEwjyqRIDXV64ZiPwcw==&product=WEB" target="_blank">
              Online Account Credit Application process
            </a>
          </p>
          <p>Instructions are available on the site to assist in completing the form, and its submission. If you'd like to assistance or speak to somebody prior to submitting an application. <br></br>
          <Link className="button primary mt-2" to="/store-locator">Contact your local branch</Link></p>
        </Col>
      </Row>
    </Layout>
  )
}

export default CreditPage

export const Head = () => {
  return (
    <>
      <title>Credit Application | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
